import { getData } from './request.js'

/**
 * 星视频、星任务-视频流量助推投后数据
 */
export function SupplementOrder(data) {
  return getData({
    url: 'FlowPush/SupplementOrder/export',
    method: 'post',
    data: data
  })
}

/**
 * 流量助推查询实时数据接口
 */
export function RealTime(data) {
  return getData({
    url: 'FlowPush/RealTime/export',
    method: 'post',
    data: data
  })
}

/**
 * 流量助推查询创意信息
 */
export function CreativeInfo(data) {
  return getData({
    url: 'FlowPush/CreativeInfo/export',
    method: 'post',
    data: data
  })
}
